<template>
    <div>
        <CCol col="12">
            <CTabs variant="pills">
                <CTab title="Açıklamalar" active>
                <CCard>
                    <CCardBody>
                    <CRow class="buttons">
                        <CButton color="success" square
                            @click="openModalEvent('create', 'inventory', 'productDescriptionForm', null, 'Açıklama Formu (Yeni Kayıt)')">
                            Ekle
                        </CButton>
                        <CButton :disabled="lastDescription.id === undefined" color="info" square
                                    @click="openModalEvent('update', 'inventory', 'productDescriptionForm', lastDescription, 'Açıklama Formu ('+lastDescription.value+')')">
                            Düzenle
                        </CButton>
                        <CButton :disabled="lastDescription.id === undefined" color="danger" square
                                    @click="openModalEvent('delete', 'inventory', 'confirm', lastDescription, lastDescription.value+' Sil', 'Bir kaydı silmek üzeresin. Bu işlemi yapmak istediğinden emin misin?', 'productDescriptionForm')">
                            Sil
                        </CButton>
                    </CRow>
                    <CDataTable
                        :items="descriptions"
                        :fields="descriptionFields"
                        :items-per-page="5"
                        :loading="loading"
                        class="modalTable"
                        pagination
                        clickable-rows
                        hover
                        @row-clicked="(item) => rowClick(item, 'lastDescription')"
                        @row-double-clicked="openModalEvent('update', 'inventory', 'productDescriptionForm', lastDescription, 'Açıklama Formu ('+lastDescription.value+')')"
                        v-on:refresh="refreshList('productDescription_list')"

                    >
                        <template #value="{item}">
                        <td>
                            <span v-html="item.value"></span>
                        </td>
                        </template>
                    </CDataTable>
                    </CCardBody>
                </CCard>

                </CTab>
                <CTab title="Fiyat Tablosu">
                <CCard>
                    <CCardBody>
                    <CRow class="buttons">
                        <CButton color="success" square @click="openModalEvent('create', 'inventory', 'productPriceForm', null, 'Fiyat Formu (Yeni Kayıt)')">
                            Ekle
                        </CButton>
                        <CButton :disabled="lastPrice.id === undefined" color="info" square
                            @click="openModalEvent('update', 'inventory', 'productPriceForm', lastPrice, 'Fiyat Formu ('+lastPrice.id+')')">
                            Düzenle
                        </CButton>
                        <CButton :disabled="lastPrice.id === undefined" color="danger" square
                            @click="openModalEvent('delete', 'inventory', 'confirm', lastPrice, 'Fiyat Sil ('+lastPrice.id+')', 'Bir kaydı silmek üzeresin. Bunu yapmak istediğine emin misin?', 'productPriceForm')">
                            Sil
                        </CButton>
                    </CRow>
                    <CDataTable
                        :items="prices"
                        :fields="priceFields"
                        :items-per-page="5"
                        :loading="loading"
                        class="modalTable"
                        pagination
                        clickable-rows
                        hover
                        @row-clicked="(item) => rowClick( item, 'lastPrice')"
                        @row-double-clicked="openModalEvent('update', 'inventory', 'productPriceForm', lastPrice, 'Fiyat Formu ('+lastPrice.id+')')"
                        v-on:refresh="refreshList('productPrice_list')"

                    >
                        <template #currency="{item}">
                            <td> {{ item.currency.name }}</td>
                        </template>
                    </CDataTable>
                    
                    </CCardBody>
                </CCard>

                </CTab>
                <CTab title="Resimler">
                <CCard>
                    <CCardBody>
                    <CRow class="buttons">
                        <CButton color="success" square @click="openModalEvent('create', 'inventory', 'productImageForm', null, 'Resim Formu (Yeni Kayıt)')">
                            Ekle
                        </CButton>
                        <CButton color="info" :disabled="lastImage.id === undefined" square
                            @click="openModalEvent('update', 'inventory', 'productImageForm', lastImage, 'Resim Formu ('+lastImage.id+')')">
                            Düzenle
                        </CButton>
                        <CButton color="danger" :disabled="lastImage.id === undefined" square
                            @click="openModalEvent('delete', 'inventory', 'confirm', lastImage, 'Resim Formu ('+lastImage.id+') Sil', 'Bir kaydı silmek üzeresin. Bunu yapmak istediğine emin misin?', 'productImageForm')">
                            Sil
                        </CButton>
                    </CRow>
                    <CDataTable
                        :items="images"
                        :fields="imageFields"
                        :items-per-page="10"
                        :active-page="1"
                        pagination
                        :loading="loading"
                        class="modalTable"
                        clickable-rows
                        hover
                        @row-clicked="(item) => rowClick(item, 'lastImage')"
                        @row-double-clicked="openModalEvent('update', 'inventory', 'productImageForm', lastImage, 'Resim Formu ('+lastImage.id+')')"
                        v-on:refresh="refreshList('productImage_list')"

                    >
                        <template #mainFlag="{item}">
                        <td>
                            <CIcon v-if="item.mainFlag" class="green"
                                    name="cil-check-alt"/>
                            <CIcon v-else name="cil-x" class="red"/>
                        </td>
                        </template>
                        <template #secondaryFlag="{item}">
                        <td>
                            <CIcon v-if="item.secondaryFlag" class="green"
                                    name="cil-check-alt"/>
                            <CIcon v-else name="cil-x" class="red"/>
                        </td>
                        </template>
                        <template #resizedUrl="{item}">
                        <td @click="openImage(item.resizedUrl)"><img :src="item.resizedUrl"></td>
                        </template>
                        <template #createTime="{item}">
                        <td> {{ item.createTime && dateFormat(item.createTime) }}</td>
                        </template>
                        <template #updateTime="{item}">
                        <td> {{ item.updateTime && dateFormat(item.updateTime) }}</td>
                        </template>
                    </CDataTable>
                    </CCardBody>
                </CCard>
                </CTab>
                <CTab title="Tavsiye Ürün Listesi">
                <CCard>
                    <CCardBody>
                    <CRow class="buttons">
                        <CButton color="success" square @click="openModalEvent('create', 'inventory', 'productUpsaleForm', null, 'Tavsiye Ürün Formu (Yeni Kayıt)')">
                            Ekle
                        </CButton>
                        <CButton :disabled="lastUpsale.id === undefined" color="info" square
                            @click="openModalEvent('update', 'inventory', 'productUpsaleForm', lastUpsale, 'Tavsiye Ürün Formu ('+lastUpsale.id+')')">
                            Düzenle
                        </CButton>
                        <CButton :disabled="lastUpsale.id === undefined" color="danger" square
                            @click="openModalEvent('delete', 'inventory', 'confirm', lastUpsale, 'Tavsiye Ürün Formu ('+lastUpsale.id+') Sil','Bir kaydı silmek üzeresin. Bunu yapmak istediğine emin misin?', 'productUpsaleForm')">
                            Sil
                        </CButton>
                    </CRow>
                    <CDataTable
                        :items="upsales"
                        :fields="upsaleFields"
                        :items-per-page="10"
                        :active-page="1"
                        pagination
                        :loading="loading"
                        class="modalTable"
                        clickable-rows
                        hover
                        @row-clicked="(item) => rowClick(item, 'lastUpsale')"
                        @row-double-clicked="openModalEvent('update', 'inventory', 'productUpsaleForm', lastUpsale, 'Tavsiye Ürün Formu ('+lastUpsale.id+')')"
                        v-on:refresh="refreshList('productUpsale_list')"
                    >
                        <template #imgUrl="{item}">
                            <td @click="openImage(item.imgUrl)"><img :src="item.imgUrl"></td>
                        </template>
                        <template #pName="{item}">
                            <td>{{ activeProducts.length > 0 && activeProducts.find(a => a.value == item.pId).label }}</td>
                        </template>
                       
                    </CDataTable>
                    </CCardBody>
                </CCard>
                </CTab>
                <CTab title="Çapraz Ürün Listesi">
                <CCard>
                    <CCardBody>
                    <CRow class="buttons">
                        <CButton color="success" square @click="openModalEvent('create', 'inventory', 'productCrosssaleForm', null, 'Çapraz Ürün Formu (Yeni Kayıt)')">
                            Ekle
                        </CButton>
                        <CButton :disabled="lastCross.id === undefined" color="info" square
                                    @click="openModalEvent('update', 'inventory', 'productCrosssaleForm', lastCross, 'Çapraz Ürün Formu ('+lastCross.id+')')">
                            Düzenle
                        </CButton>
                        <CButton :disabled="lastCross.id === undefined" color="danger" square
                            @click="openModalEvent('delete', 'inventory', 'confirm', lastCross, 'Çapraz Ürün Formu ('+lastCross.id+') Sil', 'Bir kaydı silmek üzeresin. Bunu yapmak istediğine emin misin?', 'productCrosssaleForm')">
                            Sil
                        </CButton>
                    </CRow>
                    <CDataTable
                        :items="crossSales"
                        :fields="crossFields"
                        :items-per-page="10"
                        :active-page="1"
                        pagination
                        :loading="loading"
                        class="modalTable"
                        clickable-rows
                        hover
                        @row-clicked="(item) => rowClick(item, 'lastCross')"
                        @row-double-clicked="openModalEvent('update', 'inventory', 'productCrosssaleForm', lastCrosssale, 'Çapraz Ürün Formu ('+lastCrosssale.id+')')"
                        v-on:refresh="refreshList('productCrosssale_list')"

                    >
                        <template #imgUrl="{item}">
                        <td @click="openImage(item.imgUrl)"><img :src="item.imgUrl"></td>
                        </template>
                        <template #pName="{item}">
                            <td>{{ activeProducts.length > 0 && activeProducts.find(a => a.value == item.pId).label }}</td>
                        </template>
                    </CDataTable>
                    </CCardBody>
                </CCard>
                </CTab>
            </CTabs>
            </CCol>
            <ConfirmModal
                v-if="this.form == 'confirm'"
                :show="openModal" 
                :title="modalTitle" 
                :openModalEvent="openModalEvent"
                :confirm="crud"
                :desc="modalDesc"
                :actionType="actionType"
                :loading="loading"
                :data="modalProps"
            />
            <FormModal 
                v-else
                :show="openModal" 
                :title="modalTitle" 
                :openModalEvent="openModalEvent"
                :operationEvent="crud"
                :actionType="actionType"
                :module="module"
                :form="form"
                :data="modalProps"
            />
    </div>
</template>

<script>
    import FormModal from '../../components/formModal'
    import ConfirmModal from '../../components/confirmModal'
    import moment from 'moment'
    export default{
        name: "ProductDetailList",
        props:{
            params: Object,
        },
        components:{FormModal, ConfirmModal },
        computed:{
            descriptions: function() {
                return this.$store.getters.productDescriptions
            },
            prices: function() {
                return this.$store.getters.productPrices
            },
            images: function() {
                return this.$store.getters.productImages
            },
            upsales: function() {
                return this.$store.getters.productUpsales
            },
            crossSales: function() {
                return this.$store.getters.productCrosssales
            },
            
            loading(){
                return this.$store.getters.inventoryLoading
            },
            activeProducts: function(){
            let data = []
            this.$store.getters.activeProducts.map(r => data.push({value:r.id, label: r.name}))
            return data
            
        }
        },
        watch:{
            params: function(val){
                this.data = {...val};
            },
        },
        data: function(){
            return{
                data: {...this.params},
                modalTitle: '',
                actionType: '',
                module: 'inventory',
                form: 'productDescriptionForm',
                modalProps: {},
                modalDesc: '',
                module: 'inventory',
                openModal: false,
                lastDescription: {},
                lastPrice: {},
                lastImage: {},
                lastUpsale: {},
                lastCross: {},
                reqForm: '',
                descriptionFields: [
                    {key: 'id', label: 'Id', _style: 'font-size:12px'},
                    {key: 'lang', label: 'Özellik', _style: 'font-size:12px'},
                    {key: 'value', label: 'İçerik', _style: 'font-size:12px'},
                ],
                priceFields: [
                    {key: 'id', label: 'Id', _style: 'font-size:12px'},
                    {key: 'currency', label: 'Birim', _style: 'font-size:12px'},
                    {key: 'cost', label: 'Fiyat', _style: 'font-size:12px'},
                    {key: 'retailPrice', label: 'Perakende Fiyatı', _style: 'font-size:12px'},
                    {key: 'salePrice', label: 'Satış Fiyatı', _style: 'font-size:12px'},
                ],
                imageFields: [
                    {key: 'id', label: 'Id', _style: 'font-size:12px'},
                    {key: 'resizedUrl', label: 'Resim', _style: 'font-size:12px'},
                    {key: 'imgURLWithoutCacheID', label: 'URL', _style: 'font-size:12px'},
                    {key: 'displayOrder', label: 'Sıralama', _style: 'font-size:12px'},
                    {key: 'mainFlag', label: 'Birincil', _style: 'font-size:12px'},
                    {key: 'secondaryFlag', label: 'İkincil', _style: 'font-size:12px'},
                    {key: 'createTime', label: 'Oluşt.Tarihi', _style: 'font-size:12px'},
                    {key: 'createUser', label: 'Oluşturan', _style: 'font-size:12px'},
                    {key: 'updateTime', label: 'Güncel.Tarihi', _style: 'font-size:12px'},
                    {key: 'updateUser', label: 'Güncelleyen', _style: 'font-size:12px'},
                ],
                upsaleFields: [
                    {key: 'id', label: 'Id', _style: 'font-size:12px'},
                    {key: 'displayOrder', label: 'Sıralama', _style: 'font-size:12px'},
                    {key: 'imgUrl', label: 'Ürün Resmi', _style: 'font-size:12px'},
                    {key: 'pName', label: 'Ürün Adı', _style: 'font-size:12px'}
                ],
                crossFields: [
                    {key: 'id', label: 'Id', _style: 'font-size:12px'},
                    {key: 'displayOrder', label: 'Sıralama', _style: 'font-size:12px'},
                    {key: 'imgUrl', label: 'Ürün Resmi', _style: 'font-size:12px'},
                    {key: 'pName', label: 'Ürün Adı', _style: 'font-size:12px'}
                ],
                fields: [
                    {key: 'id', label: 'Id',_style: 'font-size:12px'},
                    {key: 'skuId', label: 'SKU ID',_style: 'font-size:12px'},
                    {key: 'skuCode', label: 'SKU Kodu',_style: 'font-size:12px'},
                    {key: 'productName', label: 'Ürün Adı',_style: 'font-size:12px'},
                    {key: 'productTypeName', label: 'Ürün Tipi Adı',_style: 'font-size:12px'},
                    {key: 'amount', label: 'Adet',_style: 'font-size:12px'},
                    {key: 'barcode', label: 'Barkod',_style: 'font-size:12px'},
                    {key: 'logisticsCode', label: 'Ürün Kodu',_style: 'font-size:12px'},
                    {key: 'transferCheckFlag', label: 'Durum',_style: 'font-size:12px'},
                ],
            }
        },
        methods: {
           refreshList(key){
            this.$store.dispatch(key, {pId: this.data.id})
           },
           dateFormat(date){
                return moment(date).format('DD.MM.YYYY HH:mm:ss');
            },  
            openImage(url) {
                window.open(url, '_blank');
            },
           rowClick(item, key){
            this[key] = item
           },
           openModalEvent(actionType, module, form, data, title, desc, reqForm){
                if(form == 'productPriceForm' && data !== null){
                    data.currencyId =data.currency.id
                }else if((form == 'productUpsaleForm' || form == 'productCrosssaleForm') && data !== null){
                    data.productId = data.pId
                }
                this.openModal = !this.openModal
                this.actionType = actionType
                this.module = module
                this.form = form
                this.modalProps = data
                this.modalTitle = title
                this.modalDesc = desc
                this.reqForm = reqForm
            },
            async crud(item, action){
                let params = {
                    actionType: action,
                    pId: this.data.id,
                    id: item.id ? item.id : ''
                }
                if(this.form == 'productDescriptionForm' || this.reqForm == 'productDescriptionForm'){
                    params.lang = item.lang
                    params.value = item.value
                    await this.$store.dispatch('productDescription_action', params)
                }else if(this.form == 'productPriceForm' || this.reqForm == 'productPriceForm'){
                    params.currencyId = item.currencyId
                    params.cost = item.cost
                    params.retailPrice = item.retailPrice
                    params.salePrice = item.salePrice
                    await this.$store.dispatch('productPrices_action', params)
                } else if(this.form == 'productImageForm' || this.reqForm == 'productImageForm'){
                    const formData = new FormData();
                    formData.append("actionType", action);
                    formData.append("pId", this.data.id);
                    formData.append("id", item.id);
                    formData.append("imgURL", item.imgURL);
                    formData.append("displayOrder", item.displayOrder);
                    formData.append("mainFlag", item.mainFlag);
                    formData.append("secondaryFlag", item.secondaryFlag);
                    formData.append("file", item.file ? item.file : new File([""], "filename.txt", {type: "text/plain", lastModified: ""}));
                    await this.$store.dispatch('productImage_action', formData)
                }else if(this.form == 'productUpsaleForm'  || this.reqForm == 'productUpsaleForm'){
                    params.opId = this.data.id;
                    params.pId = item.productId;
                    params.displayOrder = item.displayOrder;
                    await this.$store.dispatch('productUpsale_action', params)
                }else if(this.form == 'productCrosssaleForm'  || this.reqForm == 'productCrosssaleForm'){
                    params.opId = this.data.id;
                    params.pId = item.productId;
                    params.displayOrder = item.displayOrder;
                    await this.$store.dispatch('productCrosssale_action', params)
                }
                if(this.$store.getters.inventoryStatus.success){
                    this.form == 'confirm' ? this.refreshList(this.reqForm.slice(0,-4)+'_list') : this.refreshList(this.form.slice(0,-4)+'_list');
                    this.openModalEvent() 
                }
            }
        }
    }
</script>
<style scoped>
   .nav-item{
        margin-right: 1rem;
    }
    .buttons{
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .buttons button{
        margin-right: .5rem
    }
</style>